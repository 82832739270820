
<!--
 * @Author: Dyf
 * @Date: 2023-02-18 18:24:03
 * @LastEditors: Dyf
 * @LastEditTime: 2023-02-21
 * @Description: 百度编辑器
-->
<style lang="scss" scoped>
::v-deep .edui-editor {
  border-radius: 10px !important;
  border: none !important;
  background: #f1f2f7 !important;

  &-toolbarbox {
    box-shadow: none !important;

    &outer {
      min-height: 50px !important;
      border-radius: 10px 10px 0 0 !important;
      box-shadow: none !important;
      background: #f1f2f7 !important;
      box-sizing: border-box !important;
      padding: 4px 8px !important;
      display: flex !important;
      align-items: center !important;
      border-bottom: 1px solid #e5e5e8 !important;
    }
  }

  &-iframeholder {
    box-sizing: border-box !important;
    padding: 20px 14px !important;
  }
}
</style>

<template>
  <div class="ueditorbox">
    <div :id="idName" style="width: 100%"></div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    idName: "", //接收参数
  },
  data() {
    return {
      editoredit: undefined,
      /* 数据默认样式 */
      editorStyle: `
      *{
        padding: 0;
        margin: 0;
        text-align: justify;
      }
      p{
          font-size: 16px;
          color: #252525;
          line-height: 26px;
          margin: 0 0 14px 0;
        }
      table,th,td{
        border: 1px solid #252525;
        border-collapse: collapse;
      }
      table p{
        text-indent: 0
      }
      img{
        max-width: 100%;
        display: block;
        margin: 26px auto;
      }
      `,
    };
  },
  /** 监听Vue实例上的数据变动 */
  watch: {},
  mounted() {
    //渲染编辑器
    this.editoredit = UE.getEditor(this.idName, this.config);
    this.editoredit.ready(() => {
      this.$emit("ueditorInitCompleteEventHandler", this.editoredit);
    });
    /* 监听编辑器内容变化 */
    this.editoredit.addListener("contentChange", () => {
      this.createStyle();
    });
    /* 编辑器渲染初始化 */
    this.editoredit.addListener("ready", () => {
      this.createStyle();
    });
  },

  beforeDestroy() {
    if (this.editoredit) this.editoredit.destroy();
  },
  methods: {
    /** 设置Ueditor的内容 */
    setUeditorContent: function (content) {
      content = content.split('<style')[0];
      this.editoredit.ready(() => {
        if (this.editoredit === undefined) return false;
        else {
          this.editoredit.setContent(content);
          this.createStyle();
        }
      });
    },
    getUeditorContent: function () {
      return this.editoredit === undefined ? "" : this.editoredit.getContent();
    },
    /** 创建style节点 */
    createStyle() {
      /* 获取编辑器中的style节点 */
      let style = this.editoredit.document.getElementById("editorStyle");
      /* 判断节点是否存在。若不存在，创建style节点 */
      if (!style) {
        style = document.createElement("style");
        style.id = "editorStyle";
        style.append(this.editorStyle); // 向style节点中添加数据默认样式 this.editorStyle
        this.editoredit.body.appendChild(style); // 将style节点添加至编辑器内容区域
      }
    },
  },
};
</script>

